import { ThemeOptions, colors } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#00205C",
    paper: colors.common.white,
  },
  primary: {
    main: "#00205C",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#0F7DFF",
    light: "#2e2e6d80",
    contrastText: "#ffffff",
  },
  error: {
    main: "#8E0923",
  },
  info: {
    main: "#904CFC",
  },
  action: {
    active: "#0F7DFF",
  },
  text: {
    primary: "#00205C",
    secondary: "#00205C",
  },
};

export default palette;
